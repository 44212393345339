import { makeStyles } from "../../../Theme/Theme";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    background: theme.palette.dark.black,
  },
  image: {
    verticalAlign: "middle !important",
  },
}));

export { useStyles };
