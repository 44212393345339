import React from "react";
import OverallIntro from "../../components/OverallHomepage/Intro/OverallIntro";
import {
  useOverallContext,
  withOverallContextProvider,
} from "../../context/OverallContext";
import { useStyles } from "./OverallHomepage.style";
import Franchise from "../../components/OverallHomepage/Franchise/Franchise";
import Layouts from "../../components/TopLocalNews/Layouts/Layouts";

const OverallHomepage = () => {
  const classes = useStyles();
  const { localNews, loading } = useOverallContext();

  return (
    <div className={classes.main_container}>
      <OverallIntro />

      <div className={classes.local_news_container}>
        <Layouts localNews={localNews} defaultTemplate loading={loading} />
      </div>

      <Franchise />
    </div>
  );
};

export default withOverallContextProvider(OverallHomepage);
