import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      width: "60%",
      margin: "auto",
      paddingBottom: theme.spacing(30),
      [theme.breakpoints.down(960)]: {
        width: "95%",
      },
    },
    row: {
      display: "flex",
      width: "100%",
      [theme.breakpoints.down(960)]: {
        display: "block",
      },
    },
    form_container: {
      display: "block",
      [theme.breakpoints.down(960)]: {
        width: "100%",
        margin: "auto",
      },
    },
    picker_label: {
      paddingLeft: theme.spacing(3),
      [theme.breakpoints.down(960)]: {
        paddingLeft: 0,
      },
    },
    text_field: {
      width: "49%",
      paddingRight: theme.spacing(2),
      [theme.breakpoints.down(960)]: {
        width: "100%",
      },
    },
    town_picker_container: {
      width: "49%",
      [theme.breakpoints.down(960)]: {
        width: "100%",
        "& .MuiAutocomplete-root": {
          width: "100% !important",
        },
      },
    },
    title: {
      marginTop: theme.spacing(20),
      color: theme.palette.themeColor.primary,
    },
    sub_title: {
      marginTop: theme.spacing(10),
      lineHeight: 1,
      color: theme.palette.themeColor.secondary,
      [theme.breakpoints.down(960)]: {
        marginTop: theme.spacing(5),
      },
    },
    divider: {
      marginBottom: theme.spacing(2),
      width: "100%",
      border: `1px solid ${theme.palette.miscellaneous.grayDivider}`,
      [theme.breakpoints.down(960)]: {
        display: "none",
      },
    },
    signup: {
      marginTop: `${theme.spacing(4.5)}px !important`,
      height: theme.spacing(4),
      backgroundColor: `${theme.palette.themeColor.primary} !important`,
      borderRadius: "unset !important",
      [theme.breakpoints.down(960)]: {
        marginTop: `${theme.spacing(3)}px !important`,
      },
    },
    error_message: {
      color: "red",
      width: "80%",
    },
    success_message: {
      color: theme.palette.themeColor.primary,
    },
    centered: {
      textAlign: "center",
      "& > div > span": {
        fontSize: "12px",
      },
      "& > div > a > span": {
        fontSize: "12px",
      },
    },
  };
});

export { useStyles };
