import React from "react";
import PropTypes from "prop-types";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import withConfig from "../../../utils/withConfig";
import OverallSubscriptionForm from "./OverallSubscriptionForm";

const OverallSubscription = ({ towns_with_clusters }) => {
  return (
    <GoogleReCaptchaProvider
      useRecaptchaNet
      reCaptchaKey={withConfig("RECAPTCHA_KEY")}
      scriptProps={{ async: true, defer: true, appendTo: "body" }}
    >
      <OverallSubscriptionForm townsWithClusters={towns_with_clusters} />
    </GoogleReCaptchaProvider>
  );
};

OverallSubscription.propTypes = {};

export default OverallSubscription;
