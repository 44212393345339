import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    top_section: {
      width: "100%",
      height: theme.spacing(70),
      display: "flex",
      justifyContent: "center",
      backgroundPosition: "center !important",
      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
      [theme.breakpoints.down(960)]: {
        height: theme.spacing(67),
        backgroundPosition: "bottom !important",
      },
    },
    top_section_contents: {
      width: "55%",
      height: "100%",
      justifyContent: "space-evenly",
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
      [theme.breakpoints.down(960)]: {
        width: "80%",
      },
    },
    links_container: {
      display: "flex",
      width: "100%",
      justifyContent: "space-evenly",
      [theme.breakpoints.down(960)]: {
        justifyContent: "center",
        flexWrap: "wrap",
      },
    },
    header: {
      fontSize: "72px !important",
      [theme.breakpoints.down(960)]: {
        fontSize: "32px !important",
      },
    },
    letter: {
      cursor: "pointer",
      "& span:hover": {
        textDecoration: "underline",
      },
      [theme.breakpoints.down(960)]: {
        marginRight: `${theme.spacing(2)}px !important`,
      },
    },
  };
});

export { useStyles };
