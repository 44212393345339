import React from "react";
import PropTypes from "prop-types";
import labels from "../../../config/labels";
import Typography from "../../../common/Typography/Typography";
import Link from "../../../common/Link/Link";
import { useStyles } from "./FormContainer.style";
import withConfig from "../../../utils/withConfig";

const FooterPolicyAndTermsText = ({ textColor }) => {
  const classes = useStyles();

  return (
    <div>
      <Typography color={textColor} level="medium_9_px">
        {labels.BY_SIGNING_UP}
      </Typography>

      <Link
        className={classes.link}
        level="medium_9_px"
        url={withConfig("TAPINTO_TERMS_OF_USE")}
        color={textColor}
        underlined
        target="_blank"
      >
        {labels.TERMS_OF_USE}
      </Link>
      <Typography color={textColor} level="medium_9_px">
        {labels.AND}
      </Typography>
      <Link
        className={classes.link}
        level="medium_9_px"
        url={withConfig("PRIVACY_POLICY")}
        color={textColor}
        underlined
        target="_blank"
      >
        {labels.PRIVACY_POLICY}
      </Link>
    </div>
  );
};
FooterPolicyAndTermsText.defaultProps = {
  textColor: "white",
};
FooterPolicyAndTermsText.propTypes = {};

export default FooterPolicyAndTermsText;
