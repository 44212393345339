import React, { useState } from "react";
import clsx from "clsx";
import Typography from "../../../common/Typography/Typography";
import Button from "../../../common/Button/Button";
import { useStyles } from "./Franchise.style";
import withConfig from "../../../utils/withConfig";
import { redirectToPage } from "../../../utils/helper";
import Lightbox from "../../../common/Lightbox/Lightbox";
import { screenWidthIsHigherThan } from "../../../hooks/useMediaQuery";
import labels from "../../../config/labels";

const Franchise = () => {
  const classes = useStyles();
  const [lightboxControls, setLightboxControls] = useState({
    toggler: false,
    slide: 0,
  });

  const handleOpenVideo = () => {
    setLightboxControls({
      toggler: !lightboxControls.toggler,
      slide: 0,
    });
  };

  return (
    <div className={classes.main_container}>
      {screenWidthIsHigherThan(960) && (
        <div className={classes.image_container}>
          <img
            className={classes.image}
            src={`${withConfig("TAP_MOBILE_DEVICES_IMAGE")}`}
          />
        </div>
      )}

      <div className={classes.right_section}>
        <Typography
          bold
          level={screenWidthIsHigherThan(960) ? "h1" : "h3_sub_header"}
        >
          {labels.FRANCHISE_TAPINTO}
        </Typography>
        <Typography
          level={screenWidthIsHigherThan(960) ? "h1_header_1" : "paragraph_1"}
        >
          {labels.OVERALL_FRANCHISE}
        </Typography>

        {!screenWidthIsHigherThan(960) && (
          <div className={classes.image_container}>
            <img
              className={classes.image}
              src={`${withConfig("TAP_MOBILE_DEVICES_IMAGE")}`}
            />
          </div>
        )}

        <div className={classes.buttons_container}>
          <Button
            level="simple_button"
            color="primary"
            onClick={() =>
              redirectToPage(withConfig("FRANCHISE_TAPINTO"), "_top")
            }
            classes={{
              root: clsx(
                classes.button,
                classes.button_label,
                classes.learn_more_button
              ),
            }}
            ariaLabel={labels.LEARN_MORE}
          >
            {labels.LEARN_MORE}
          </Button>
          <Button
            level="simple_button"
            color="primary"
            onClick={handleOpenVideo}
            classes={{
              root: clsx(
                classes.button,
                classes.button_label,
                classes.watch_video_button
              ),
            }}
            ariaLabel={labels.WATCH_VIDEO}
          >
            {labels.WATCH_VIDEO}
          </Button>
        </div>
      </div>
      <Lightbox
        items={[
          {
            contentUrl: withConfig("FRANCHISE_YOUTUBE_VIDEO"),
            src: withConfig("FRANCHISE_YOUTUBE_VIDEO"),
            width: 80,
            height: 100,
            isVideo: true,
            alt_text: labels.FRANCHISE_TAPINTO,
            captions: labels.FRANCHISE_TAPINTO,
            credits: "TAPinto",
          },
        ]}
        controls={lightboxControls}
      />
    </div>
  );
};

export default Franchise;
