import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Typography from "../../../common/Typography/Typography";
import Link from "../../../common/Link/Link";
import { useStyles } from "./Header.style";
import { screenWidthIsLowerThan } from "../../../hooks/useMediaQuery";
import labels from "../../../config/labels";
import withConfig from "../../../utils/withConfig";

const Header = ({ alphabet, executeScroll }) => {
  const classes = useStyles();

  return (
    <div
      style={{
        background: `linear-gradient(0deg, rgba(19, 18, 18, 0.75) 0%, rgba(19, 18, 18, 0.75) 100%), url(${withConfig(
          "TAPINTO_SITES_IMAGE"
        )}), lightgray 50% / cover no-repeat`,
      }}
      className={classes.top_section}
    >
      <div className={classes.top_section_contents}>
        <Typography
          bold
          color="tapintoOrange"
          level="h1"
          className={classes.header}
        >
          {labels.ORIGINAL_LOCAL_NEWS_YOU_CAN_TRUST}
        </Typography>
        <Typography
          color="white"
          level={screenWidthIsLowerThan(960) ? "t5_text_5" : "h1_header_1"}
        >
          {labels.ACCESS_ORIGINAL_LOCAL_NEWS}
        </Typography>

        <div className={classes.links_container}>
          {alphabet.map((letter) => (
            <Fragment key={letter}>
              <Link
                color="white"
                level="paragraph_1"
                className={classes.letter}
                children={letter}
                onClick={(e) => executeScroll(e, `${letter}-block`)}
              />
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  alphabet: PropTypes.arrayOf(PropTypes.string),
  executeScroll: PropTypes.func,
};

export default Header;
