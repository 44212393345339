import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      height: `calc(100vh - ${theme.mastheadHeights.overall_desktop})`,
      width: "100%",
      overflow: "hidden",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
      [theme.breakpoints.down(1050)]: {
        height: "fit-content",
        flexWrap: "wrap",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
      },
      [theme.breakpoints.down(640)]: {
        flexDirection: "column",
      },
    },
    background_container: {
      flex: 1,
      height: "100%",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      [theme.breakpoints.down(960)]: {
        width: "100%",
      },
    },
    left_section: {
      height: "100%",
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      paddingLeft: theme.spacing(9),
      [theme.breakpoints.down(960)]: {
        width: "100%",
        padding: theme.spacing(3),
      },
    },
    find_your_town: {
      fontSize: "64px",
      paddingBottom: theme.spacing(7),
      [theme.breakpoints.down(1400)]: {
        fontSize: "54px",
      },
      [theme.breakpoints.down(960)]: {
        fontSize: "30px",
        paddingBottom: theme.spacing(5),
        paddingTop: theme.spacing(1),
      },
    },
    town_name_or_zip: {
      fontWeight: 500,
      paddingBottom: theme.spacing(4),
      [theme.breakpoints.down(960)]: {
        paddingBottom: theme.spacing(2),
      },
    },
    town_picker_container: {
      width: "80%",
      paddingBottom: theme.spacing(4),
      [theme.breakpoints.down(960)]: {
        width: "100%",
      },
    },
    location_icon: {
      height: theme.spacing(3),
    },
    town_picker_input_focused: {
      border: "none !important",
      boxShadow: "none !important",
      backgroundColor: "transparent !important",
    },
    town_picker_focused: {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme.palette.tapintoBranding.tapintoOrange} !important`,
      },
    },
    arrow_icon: {
      marginLeft: theme.spacing(2),
    },
    town_picker_paper: {
      borderRadius: "0px !important",
      paddingBottom: "0px !important",
    },
    town_picker_option: {
      borderBottom: `1px solid ${theme.palette.light.grey}`,
      '&[data-focus="true"]': {
        backgroundColor: `${theme.palette.light.orange} !important`,
      },
    },
  };
});

export { useStyles };
