import React from "react";
import PropTypes from "prop-types";
import Typography from "../../../Typography/Typography";
import VideoPlayer from "../../../VideoPlayer/VideoPlayer";
import { useStyles } from "./WithCustomCaptionAndCredits.style";
import { screenWidthIsHigherThan } from "../../../../hooks/useMediaQuery";

const SlideWithCustomCaptionAndCredits = ({
  src,
  caption,
  credits,
  altText,
  isVideo,
}) => {
  const classes = useStyles();
  return (
    <div>
      {isVideo ? (
        <VideoPlayer
          url={src}
          width="90vw"
          {...(screenWidthIsHigherThan(960) && { height: "80vh" })}
        />
      ) : (
        <img src={src} alt={altText} className={classes.image} />
      )}

      {(caption || credits) && (
        <div className={classes.container}>
          {caption && (
            <div>
              <Typography level="t4_text_4" color="white">
                <strong>Caption: </strong>
                {caption}
              </Typography>
            </div>
          )}
          {credits && (
            <div>
              <Typography level="t4_text_4" color="white">
                <strong>Credits: </strong>
                {credits}
              </Typography>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
SlideWithCustomCaptionAndCredits.propTypes = {
  src: PropTypes.string,
  caption: PropTypes.string,
  credits: PropTypes.string,
  altText: PropTypes.string,
  isVideo: PropTypes.bool,
};

export default SlideWithCustomCaptionAndCredits;
