import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import Header from "./Header/Header";
import Contents from "./Contents/Contents";
import { loadTownsWithClusters } from "../../services/townService";

const TownsIndex = () => {
  const [townsForIndex, setTownsForIndex] = useState([]);

  const fetchTownsWithClustersForIndex = useCallback(async () => {
    const response = await loadTownsWithClusters(true);

    if (response?.status === 200) {
      setTownsForIndex(response?.data?.towns_with_clusters);
    }
  }, [townsForIndex]);

  useEffect(() => {
    if (townsForIndex.length === 0) {
      fetchTownsWithClustersForIndex();
    }
  }, [townsForIndex]);

  const alphabet = useMemo(
    () => [...new Set(townsForIndex.map((town) => town.letter))],
    [townsForIndex]
  );

  const executeScroll = (e, elementId) => {
    e.preventDefault();
    const element = document.getElementById(elementId);

    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  return (
    <>
      <Header alphabet={alphabet} executeScroll={executeScroll} />
      <Contents alphabet={alphabet} towns={townsForIndex} />
    </>
  );
};

export default memo(TownsIndex);
