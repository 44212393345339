import React from "react";
import PropTypes from "prop-types";
import FsLightbox from "fslightbox-react";
import CustomSlide from "./CustomSlides/CustomSlide";

const Lightbox = ({ items, controls, options, customClasses }) => {
  const buildSlidesData = () => {
    return items.map((item) => {
      return {
        src: item.contentUrl,
        alt: item.alt_text,
        ...item,
      };
    });
  };
  const buildCustomSources = () => {
    return buildSlidesData()?.map((slide, index) => (
      <div>
        <CustomSlide
          slide={slide}
          type={options?.slideType}
          customClasses={customClasses}
        />
      </div>
    ));
  };
  return (
    <FsLightbox
      toggler={controls.toggler}
      sources={buildCustomSources()}
      sourceIndex={controls?.slide}
    />
  );
};

Lightbox.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      contentUrl: PropTypes.string,
      src: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
      isVideo: PropTypes.bool,
      alt_text: PropTypes.string,
      captions: PropTypes.string,
      credits: PropTypes.string,
    })
  ).isRequired,
  controls: PropTypes.shape({
    toggle: PropTypes.bool,
    slide: PropTypes.number,
  }),
  options: PropTypes.shape({
    slideType: PropTypes.string,
  }),
  customClasses: PropTypes.object,
};

Lightbox.defaultProps = {
  options: {
    slideType: "with-custom-credits-and-caption",
  },
};

export default Lightbox;
