import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    letter_header: {
      fontWeight: 600,
      fontSize: "32px",
      marginBottom: theme.spacing(7),
      flex: "100%",
      [theme.breakpoints.down(960)]: {
        fontSize: "24px",
      },
    },
    index_container: {
      paddingTop: theme.spacing(4),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    index_block: {
      width: "85%",
      display: "flex",
      flexWrap: "wrap",
      "@media (any-pointer: coarse)": {
        width: "90%",
      },
      [theme.breakpoints.down(960)]: {
        width: "90%",
      },
    },
    town_name: {
      fontWeight: 600,
      fontSize: "32px",
      lineHeight: "34px",
      [theme.breakpoints.down(960)]: {
        fontSize: "24px",
      },
    },
    town_link: {
      "&:hover": {
        textDecoration: "underline",
        color: theme.palette.light.gray,
      },
      [theme.breakpoints.down(960)]: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    cluster_towns: {
      width: "100%",
      [theme.breakpoints.down(960)]: {
        display: "flex",
        flexWrap: "wrap",
      },
    },
    town_container: {
      flex: "50%",
      marginBottom: theme.spacing(7),
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      paddingRight: theme.spacing(6),
      minWidth: 0,
      "@media (any-pointer: coarse)": {
        flex: "100%",
        paddingRight: 0,
      },
      [theme.breakpoints.down(960)]: {
        flex: "100%",
        paddingRight: 0,
      },
    },
    image: {
      maxHeight: theme.spacing(10),
    },
  };
});

export { useStyles };
