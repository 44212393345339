import React, { Fragment } from "react";
import { useStyles } from "./Contents.styles";
import Typography from "../../../common/Typography/Typography";
import labels from "../../../config/labels";
import { checkIfIsNotTheLastItem } from "../../../utils/helper";

const Contents = ({ alphabet, towns }) => {
  const classes = useStyles();

  const filterTownsByLetter = (letter) =>
    towns.filter((town) => town.letter === letter);

  return (
    <div className={classes.index_container}>
      {alphabet.map((letter) => (
        <div
          id={`${letter}-block`}
          className={classes.index_block}
          key={`${letter}-block`}
        >
          <Typography level="h1" className={classes.letter_header}>
            {letter}
          </Typography>

          {filterTownsByLetter(letter).map((town) => (
            <div className={classes.town_container} key={town.id}>
              <a href={town.path} target="_top" className={classes.town_link}>
                {town.image ? (
                  <img
                    src={town.image}
                    alt={`${town.name}-banner`}
                    className={classes.image}
                  />
                ) : (
                  <>
                    <Typography
                      color="tapintoGreen"
                      level="paragraph_1"
                      className={classes.town_name}
                    >
                      {labels.TAP}
                    </Typography>
                    <Typography
                      color="tapintoOrange"
                      level="paragraph_1"
                      className={classes.town_name}
                    >
                      {labels.INTO}
                    </Typography>
                    <Typography
                      level="paragraph_1"
                      className={classes.town_name}
                    >
                      {town.name}
                    </Typography>
                  </>
                )}

                {town.cluster_towns?.length > 0 && (
                  <div className={classes.cluster_towns}>
                    {town.cluster_towns.map((clusterTown, index) => (
                      <Fragment key={clusterTown.id}>
                        <Typography color="gray" level="paragraph_1">
                          {checkIfIsNotTheLastItem(index, town.cluster_towns)
                            ? clusterTown.name + ", "
                            : clusterTown.name}
                        </Typography>
                      </Fragment>
                    ))}
                  </div>
                )}
              </a>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Contents;
