import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getTopStories } from "../services/homePage";

const OverallContext = React.createContext({});

const OverallContextProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [localNews, setLocalNews] = useState({
    mainStory: undefined,
    secondaryStories: [],
  });

  useEffect(() => {
    const loadLocalNews = async () => {
      setLoading(true);
      const response = await getTopStories({ limit: 4 });
      if (
        response &&
        response?.status === 200 &&
        response?.data?.top_stories?.length > 0
      ) {
        const topStoriesCount = response.data.top_stories.length;

        setLocalNews({
          mainStory: response.data.top_stories[0],
          secondaryStories: response.data.top_stories.slice(1, topStoriesCount),
        });
      }
      setLoading(false);
    };

    loadLocalNews();
  }, []);

  return (
    <OverallContext.Provider
      value={{
        localNews,
        loading,
      }}
    >
      {children}
    </OverallContext.Provider>
  );
};

OverallContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useOverallContext = () => {
  const OverallContextValues = useContext(OverallContext);

  if (!OverallContextValues) {
    throw new Error(
      "useContext must be used within a descendant of the OverallContextProvider"
    );
  }

  return OverallContextValues;
};

const withOverallContextProvider = (Component) => {
  const ComponentWithProvider = (props) => (
    <OverallContextProvider>
      <Component {...props} />
    </OverallContextProvider>
  );

  return ComponentWithProvider;
};

export { useOverallContext, withOverallContextProvider };
