import { makeStyles } from "../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    main_container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    local_news_container: {
      width: "90vw",
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(7),
      [theme.breakpoints.down(960)]: {
        padding: `${theme.spacing(4)}px 0px`,
      },
    },
  };
});

export { useStyles };
