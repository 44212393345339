import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    main_container: {
      backgroundColor: theme.palette.light.grey,
      width: "100%",
      height: theme.spacing(85),
      paddingLeft: theme.spacing(10),
      paddingRight: theme.spacing(10),
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down(960)]: {
        height: "fit-content",
        padding: theme.spacing(5),
      },
      "@media (any-pointer: coarse)": {
        height: "fit-content",
        padding: theme.spacing(5),
      },
    },
    right_section: {
      width: "50%",
      paddingLeft: "10%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      [theme.breakpoints.down(960)]: {
        paddingLeft: 0,
        width: "100%",
      },
      "@media (any-pointer: coarse)": {
        paddingLeft: 0,
        width: "100%",
      },
    },
    buttons_container: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down(1320)]: {
        flexDirection: "column",
        height: "22%",
      },
    },
    button: {
      borderRadius: "0px !important",
      padding: `${theme.spacing(1)}px !important`,
      width: "47% !important",
      [theme.breakpoints.down(1320)]: {
        width: "100% !important",
      },
    },
    button_label: {
      "& .MuiButton-label": {
        fontSize: "26px",
        fontFamily: "Montserrat, sans-serif",
        color: theme.palette.miscellaneous.black,
        fontWeight: 500,
        [theme.breakpoints.down(960)]: {
          fontSize: "16px",
        },
        "@media (any-pointer: coarse)": {
          fontSize: "16px",
        },
      },
    },
    learn_more_button: {
      border: `3px solid ${theme.palette.tapintoBranding.tapintoOrange} !important`,
      [theme.breakpoints.down(960)]: {
        marginBottom: `${theme.spacing(2)}px !important`,
      },
      "@media (any-pointer: coarse)": {
        marginBottom: `${theme.spacing(2)}px !important`,
      },
    },
    watch_video_button: {
      backgroundColor: `${theme.palette.tapintoBranding.tapintoOrange} !important`,
    },
    image_container: {
      width: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down(960)]: {
        width: "100%",
        padding: `${theme.spacing(3)}px 0px`,
      },
      "@media (any-pointer: coarse)": {
        width: "100%",
        padding: `${theme.spacing(3)}px 0px`,
      },
    },
    image: {
      width: "auto",
      height: "100%",
    },
  };
});

export { useStyles };
